import { PersonName, PersonNameVariables } from './__generated__/PersonName'

import { gql } from 'apollo-boost'
import { useApolloClient } from '@apollo/react-hooks'

const QUERY = gql`
  query PersonName($personId: String!) {
    person(id: $personId) {
      id
      firstName
      lastName
    }
  }
`

export const useGetPersonNameLazyQuery = () => {
  const client = useApolloClient()

  return async (personId: string) => {
    return await client.query<PersonName, PersonNameVariables>({
      query: QUERY,
      variables: {
        personId
      }
    })
  }
}
