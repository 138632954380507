import { Box, Button, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'

import { Alert } from '@material-ui/lab'
import { MarketplaceSelector } from './MarketplaceSelector'
import { PlusCategorySelector } from './PlusCategorySelector'
import { PlusTypeSelector } from './PlusTypeSelector'
import { Spacer } from '@vestaboard/installables'
import { TemplateSelector } from './TemplateSelector'

interface IDeepLink {
  disabled?: boolean
  value: string
  onChange: (value: string) => void
}

enum DeepLinkConfigType {
  String = 'String',
  MarketplaceListing = 'MarketplaceListing',
  Template = 'Template',
  PlusFeatureType = 'PlusFeatureType',
  PlusCategory = 'PlusCategory'
}

interface IDeepLinkConfig {
  title: string
  type: DeepLinkConfigType
  key: string
  value?: string
}

interface IDeepLinkItem {
  title: string
  path: string
  config: IDeepLinkConfig[]
}

const deepLinks: IDeepLinkItem[] = [
  {
    title: 'Composer (Auto)',
    path: '/compose',
    config: []
  },
  {
    title: 'Plus Manage',
    path: '/plus/manage',
    config: []
  },
  {
    title: 'Plus Discover',
    path: '/plus/discover',
    config: []
  },
  {
    title: 'Plus Search',
    path: '/plus/search',
    config: []
  },
  {
    title: 'Plus Feature Type',
    path: '/plus/feature-types/:id',
    config: [
      {
        title: 'Feature Type',
        type: DeepLinkConfigType.PlusFeatureType,
        key: 'id'
      }
    ]
  },
  {
    title: 'Plus Category',
    path: '/plus/category/:id',
    config: [
      {
        title: 'Category',
        type: DeepLinkConfigType.PlusCategory,
        key: 'id'
      }
    ]
  },
  {
    title: 'Plus Subscription Config',
    path: '/subscription/:id/config',
    config: [
      {
        title: 'Subscription ID',
        type: DeepLinkConfigType.String,
        key: 'id'
      }
    ]
  },
  {
    title: 'Install Marketplace Listing',
    path: '/marketplace-listing/:id/install',
    config: [
      {
        title: 'Marketplace Listing',
        type: DeepLinkConfigType.MarketplaceListing,
        key: 'id'
      }
    ]
  },
  {
    title: 'Marketplace Listing',
    path: '/marketplace-listing/:id',
    config: [
      {
        title: 'Marketplace Listing',
        type: DeepLinkConfigType.MarketplaceListing,
        key: 'id'
      }
    ]
  },
  {
    title: 'Settings',
    path: '/settings',
    config: []
  },
  {
    title: 'Advanced Settings',
    path: '/deeplink/settings/dynamic',
    config: [
      {
        title: 'Key',
        type: DeepLinkConfigType.String,
        key: 'key'
      }
    ]
  },
  {
    title: 'Dynamic Settings',
    path: '/deeplink/settings/dynamic/:key',
    config: [
      {
        title: 'Key',
        type: DeepLinkConfigType.String,
        key: 'key'
      }
    ]
  },
  {
    title: 'Settings Timezone',
    path: '/settings/timezone',
    config: []
  },
  {
    title: 'Settings Quiet Hours',
    path: '/settings/quiet-hours',
    config: []
  },
  {
    title: 'Settings Users',
    path: '/settings/users',
    config: []
  },
  {
    title: 'Settings Simulator',
    path: '/settings/simulator',
    config: []
  },
  {
    title: 'Settings Billing',
    path: '/settings/vestaboard-plus',
    config: []
  },
  {
    title: 'Settings Edit Profile',
    path: '/settings/users/edit',
    config: []
  },
  {
    title: 'Compose (Visual)',
    path: '/compose/:token',
    config: [
      {
        title: 'Character Codes',
        type: DeepLinkConfigType.String,
        key: 'token'
      }
    ]
  },
  {
    title: 'Activate Plus',
    path: '/buy-plus/:token',
    config: [
      {
        title: 'Activation Token',
        type: DeepLinkConfigType.String,
        key: 'token'
      }
    ]
  },
  {
    title: 'Share',
    path: '/view-share/:token',
    config: [
      {
        title: 'Share Token',
        type: DeepLinkConfigType.String,
        key: 'token'
      }
    ]
  },
  {
    title: 'R/W API',
    path: '/api/rw',
    config: []
  },
  {
    title: 'Inspiration',
    path: '/messages/inspiration',
    config: []
  },
  {
    title: 'History',
    path: '/messages/history',
    config: []
  },
  {
    title: 'Favorites',
    path: '/messages/favorites',
    config: []
  },
  {
    title: 'Drafts',
    path: '/messages/drafts',
    config: []
  },
  {
    title: 'Message Search',
    path: '/messages/search',
    config: []
  },
  {
    title: 'Notifications',
    path: '/notifications',
    config: []
  },
  {
    title: 'Templates',
    path: '/templates',
    config: []
  },
  {
    title: 'Template',
    path: '/templates/:id',
    config: [
      {
        title: 'Template Category',
        type: DeepLinkConfigType.Template,
        key: 'id'
      }
    ]
  },
  {
    title: 'Settings - Publish Digitally',
    path: '/settings/publish',
    config: []
  },
  {
    title: 'Transition Settings',
    path: '/settings/advanced/transitions',
    config: []
  }
].sort((a, b) => a.title.localeCompare(b.title))

export const DeepLink = (props: IDeepLink) => {
  const [type, setType] = useState('')
  const [configSettings, setConfigSettings] = useState<IDeepLinkConfig[]>([])

  useEffect(() => {
    const config = deepLinks.find(deepLink => deepLink.path === type)?.config || []
    setConfigSettings(config.map(configItem => ({ ...configItem, value: '' })))
  }, [type])

  const value = useMemo(() => {
    return type.replace(/:(\w+)/g, (match, key) => {
      const configItem = configSettings.find(configItem => configItem.key === key)
      return configItem?.value || ''
    })
  }, [configSettings, type])

  return props.value ? (
    <>
      <Alert severity='info'>{props.value}</Alert>
      <Spacer size='medium' />
      <Button
        disabled={props.disabled}
        variant='outlined'
        onClick={() => {
          if (props.value !== '') {
            props.onChange('')
          }
        }}>
        Clear Deep Link
      </Button>
    </>
  ) : (
    <>
      <Select
        fullWidth
        disabled={props.disabled}
        variant='outlined'
        value={type}
        onChange={e => setType(e.target.value as string)}>
        <MenuItem>Select</MenuItem>
        {deepLinks.map(deepLink => (
          <MenuItem key={deepLink.title} value={deepLink.path}>
            {deepLink.title}
          </MenuItem>
        ))}
      </Select>
      {configSettings.map((configItem, index) => (
        <Box key={configItem.title} marginTop={1}>
          {configItem.type === DeepLinkConfigType.MarketplaceListing ? (
            <MarketplaceSelector
              disabled={props.disabled}
              value={configItem.value || ''}
              onChange={value => {
                setConfigSettings(
                  configSettings.map((item, itemIndex) => (itemIndex === index ? { ...item, value } : item))
                )
              }}
            />
          ) : configItem.type === DeepLinkConfigType.Template ? (
            <TemplateSelector
              value={configItem.value || ''}
              onChange={value => {
                setConfigSettings(
                  configSettings.map((item, itemIndex) => (itemIndex === index ? { ...item, value } : item))
                )
              }}
            />
          ) : configItem.type === DeepLinkConfigType.PlusFeatureType ? (
            <PlusTypeSelector
              value={configItem.value || ''}
              onChange={value => {
                setConfigSettings(
                  configSettings.map((item, itemIndex) => (itemIndex === index ? { ...item, value } : item))
                )
              }}
            />
          ) : configItem.type === DeepLinkConfigType.PlusCategory ? (
            <PlusCategorySelector
              value={configItem.value || ''}
              onChange={value => {
                setConfigSettings(
                  configSettings.map((item, itemIndex) => (itemIndex === index ? { ...item, value } : item))
                )
              }}
            />
          ) : (
            <TextField
              fullWidth
              disabled={props.disabled}
              label={configItem.title}
              variant='outlined'
              value={configItem.value}
              onChange={e => {
                setConfigSettings(
                  configSettings.map((item, itemIndex) =>
                    itemIndex === index ? { ...item, value: e.target.value } : item
                  )
                )
              }}
            />
          )}
          {type ? (
            <>
              <Spacer size='medium' />
              <Alert severity='info'>{value}</Alert>
            </>
          ) : null}
        </Box>
      ))}
      <Spacer size='medium' />
      <Button
        disabled={props.disabled || !!configSettings.filter(item => !item.value).length}
        variant='outlined'
        onClick={() => {
          const deepLinkValue = `${value}`
          if (props.value !== deepLinkValue) {
            props.onChange(deepLinkValue)
          }
        }}>
        Set Deep Link
      </Button>
    </>
  )
}
