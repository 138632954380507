import { CircularProgress, Link as MuiLink, Typography } from '@material-ui/core'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Alert } from '@material-ui/lab'
import React from 'react'
import { SegmentForm } from '../components/SegmentForm'
import { useGetNotificationSegment } from '../hooks/useGetNotificationSegment'
import { useListNotificationSegments } from '../hooks/useListNotificationSegments'
import { useUpdateNotificationSegment } from '../hooks/useUpdateNotificationSegment'

export const UpdateSegment = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const { id } = useParams<{ id: string }>()
  const [update] = useUpdateNotificationSegment()
  const { data, loading, error } = useGetNotificationSegment(id)

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Alert severity='error'>There was an error loading the segment</Alert>
  ) : data?.notificationSegment.__typename === 'NotificationSegmentV2' ? (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Update Segment</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <SegmentForm
        data={{
          title: data.notificationSegment.title,
          subscribers: (data.notificationSegment.subscribers || []).map(subscriber => ({
            id: subscriber.id,
            title: subscriber.title
          }))
        }}
        onSubmit={async input => {
          const result = await update({
            variables: {
              input: {
                ...input,
                id
              }
            },
            refetchQueries: [
              {
                query: useListNotificationSegments.QUERY,
                variables: {
                  input: {
                    cursor: null,
                    limit: 25
                  }
                }
              }
            ]
          })

          if (result.errors) {
            addToast('There was an error saving the segment', {
              appearance: 'error'
            })
            return
          }

          if (result.data?.updateNotificationSegment.__typename === 'NotificationSegmentErrorV2') {
            addToast('There was an error saving the notification', {
              appearance: 'error'
            })
            return
          } else {
            history.push('/notifications/segments')
          }
        }}
      />
    </>
  ) : (
    <Alert severity='error'>There was an error loading the segment</Alert>
  )
}
