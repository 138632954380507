import * as Yup from 'yup'

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

import { Alert } from '@material-ui/lab'
import { CsvImportButton } from '../../../ui/CsvImportButton'
import React from 'react'
import { SubscriberSelector } from './SubscriberSelector'
import { useFormik } from 'formik'
import { useGetPersonNameLazyQuery } from '../../../api/useGetPersonName'
import { useHistory } from 'react-router'

interface ISubscriber {
  id: string
  title: string
}

interface IForm {
  title: string
  subscribers: ISubscriber[]
}

interface IFormValues {
  title: string
  personIds: string[]
}

interface ISegmentForm {
  disabled?: boolean
  data?: IForm
  onSubmit: (values: IFormValues) => void
}

const FormSchema = Yup.object().shape({
  title: Yup.string().required('You must enter a title'),
  subscribers: Yup.array().of(
    Yup.object({
      id: Yup.string().required('You must select a segment id'),
      title: Yup.string().required('You must select a segment title')
    })
  )
})

export const SegmentForm = (props: ISegmentForm) => {
  const history = useHistory()
  const fetchPerson = useGetPersonNameLazyQuery()

  const initialValues: IForm = props.data || {
    title: '',
    subscribers: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: values => {
      props.onSubmit({
        personIds: values.subscribers.map(subscriber => subscriber.id),
        title: values.title
      })
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <TableContainer
        component={Paper}
        style={{
          maxWidth: 800
        }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Title</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  disabled={props.disabled}
                  fullWidth
                  variant='outlined'
                  id='title'
                  name='title'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.submitCount && formik.errors.title ? (
                  <>
                    <Spacer size='small' />
                    <Alert severity='error'>{formik.errors.title}</Alert>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                valign='top'
                style={{
                  verticalAlign: 'top'
                }}>
                <div>
                  <Typography>Subscribers</Typography>
                  <Spacer size='small' />
                  <CsvImportButton
                    mainButtonText='CSV Import'
                    buttonText='Add to Segment'
                    buttonVariant='primary'
                    mappings={['personId', 'name']}
                    handleImport={async records => {
                      const subscribers = await Promise.all(
                        (records as { personId: string; name?: string }[])
                          .filter(record => !!record.personId)
                          .map(async record => {
                            if (record.name) {
                              return {
                                id: record.personId,
                                title: record.name
                              }
                            }

                            const data = await fetchPerson(record.personId)

                            if (data.data.person) {
                              return {
                                id: data.data.person.id,
                                title: `${data.data.person.firstName || ''} ${data.data.person.lastName || ''}`.trim()
                              }
                            }

                            return {
                              id: record.personId,
                              title: ''
                            }
                          })
                      )

                      formik.setFieldValue('subscribers', [...formik.values.subscribers, ...subscribers])
                    }}
                  />
                </div>
              </TableCell>
              <TableCell>
                <SubscriberSelector
                  disabled={props.disabled}
                  subscribers={formik.values.subscribers}
                  setSubscribers={subscribers => formik.setFieldValue('subscribers', subscribers)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer size='extraLarge' />
      <FlexHorizontal>
        <Button
          variant='contained'
          size='large'
          color='default'
          onClick={() => {
            history.push('/notifications')
          }}>
          Cancel
        </Button>
        <Box width={14} />
        <Button type='submit' variant='contained' size='large' color='primary' disabled={props.disabled}>
          Save
        </Button>
      </FlexHorizontal>
    </form>
  )
}
